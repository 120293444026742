import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatTabGroup } from '@angular/material/tabs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { WachtlijstdialogComponent } from './../wachtlijstdialog/wachtlijstdialog.component';

@Component({
  selector: 'app-docenten-widget',
  templateUrl: './docenten-widget.component.html',
  styleUrls: ['./docenten-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocentenWidgetComponent implements OnInit {

  inschrijfOpleidingen: any;
  @ViewChild('tabs') tabs: MatTabGroup;
  gekozenOpleiding: string;

  mainGroup: FormGroup;
  noDonatorGroup: FormGroup;

  constructor(private fs: AngularFirestore,private snackbar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getInschrijfOpleidingen();

    this.mainGroup = new FormGroup({
      studentVoorNaam: new FormControl(),
      studentAchterNaam: new FormControl(),
      donateur: new FormControl(),
      registratieNummer: new FormControl(),
      email: new FormControl(),
      telNr: new FormControl(),
      geboorteDatum: new FormControl()
    });

    this.noDonatorGroup = new FormGroup({
      straatEnHuis: new FormControl(),
      postcodeEnPlaats: new FormControl(),
      rekNr: new FormControl('',[Validators.pattern(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i)]),
    });
  }

  setIndexSelectedTab(index: number){
    this.tabs.selectedIndex = index;  
  }

  addNameToForm(o: any){
    if (o.max - o.gereserveerd < 1){
      
      let dialogRef = this.dialog.open(WachtlijstdialogComponent, {
        data: o,
        maxWidth: '500px',
        minWidth: '200px'
      });

    } else {
      this.setIndexSelectedTab(1);
      this.gekozenOpleiding = o.naam;
    }
  }

  getInschrijfOpleidingen(){
    this.fs.collection("Opleidingen").doc("InschrijfOpleidingen").valueChanges()
    .subscribe( data => {
      let tempList = [];
      tempList = data['opleidingen'];
      tempList.sort((a,b) => (a.naam > b.naam)  ? 1 : -1);
      this.inschrijfOpleidingen = tempList;
    });
  }

  buttonValidity() : Boolean {
    if (this.mainGroup.valid &&
        this.mainGroup.controls['donateur'].value == 1 && 
        this.mainGroup.controls['registratieNummer'].value?.length > 2){
        return true;
    } else if (this.mainGroup.valid && this.noDonatorGroup.valid) {
      return true;
    } else {
      return false;
    }
  }

  slaOp(){
    const dataToSend = {
      opleiding: this.gekozenOpleiding,
      voorNaam: this.mainGroup.controls.studentVoorNaam.value,
      achterNaam: this.mainGroup.controls.studentAchterNaam.value,
      donateur: this.mainGroup.controls.donateur.value == 1 ? "ja" : "nee",
      registratieNr: this.mainGroup.controls.registratieNummer.value,
      straatEnHuisNr: this.noDonatorGroup.controls.straatEnHuis.value,
      postcodeEnPlaats: this.noDonatorGroup.controls.postcodeEnPlaats.value,
      geboorteDatum: this.mainGroup.controls.geboorteDatum.value,
      rekNr: this.noDonatorGroup.controls.rekNr.value,
      email: this.mainGroup.controls.email.value,
      telNr: this.mainGroup.controls.telNr.value,
      aanvraagtijd: new Date()
    };

    this.fs.collection("Inschrijvingen").add(dataToSend).then( data => {
      this.openSnackbar("Succesvol aangevraagd! Wij nemen contact met u op.");
      this.mainGroup.reset();
      this.noDonatorGroup.reset();
      this.setIndexSelectedTab(0);
    }).catch( error => {
      this.openSnackbar("Aanvraag niet gelukt! Neem contact op met ons.");
    });
  }

  openSnackbar(message: string){
    return this.snackbar.open(message, null , {
      panelClass: 'success',
      duration: 5000
    });
  }

}
