import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { OpleidingenService } from './../opleidingen.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  alineas: any;
  subtitel: any;
  opleidingen: any;
  leave: boolean;
  constructor(private fs: AngularFirestore, private router: Router, 
    private oplService: OpleidingenService) { }

  ngOnInit(): void {
    this.getHomeData();
    this.getOpleidingen();
  }

  getHomeData(){
    this.fs.collection('home').valueChanges().subscribe(
      data => {
        this.alineas = data[0]['welkomtekst'];
      });
  }

  getOpleidingen(){
    this.fs.collection("Opleidingen").doc("1").valueChanges().subscribe(
      data => {
        this.subtitel = data['subtitel'];
        let tempList = [];
        for (let x of data['opleidingen']){
          if (tempList.length < 3){
            tempList.push(x)
          }
        }
        this.opleidingen = tempList;
      });
  }

  goToOpleiding(opleiding?: any){
    this.leave = true;
    setTimeout( () => {
      this.oplService.selectedOpleiding = opleiding;
      this.router.navigateByUrl('/opleidingen');
    }, 1000)
  }

}
