<div class="page-content">
    <div class="banner-box">
        <img src="../../assets/docenten.jpg" alt="">
        <div class="titel-box">DE DOCENTEN</div>
    </div>
    <div class="body">
        <div *ngFor="let docent of docenten" class="bestuur-item">
            <p class="naam-mobile"> {{docent.naam}} </p>
            <div class="image-div">
                <img *ngIf="docent.afbeelding" [src]="docent.afbeelding" class="foto"/>
                <img *ngIf="!docent.afbeelding" src="../../assets/person_icon.png" class="foto"/>
            </div>
            <div class="beschrijving">
                <p class="naam"> {{docent.naam}} </p>
                <p *ngFor="let x of docent.tekst" class="verhaal"> {{x.alinea}} </p>
            </div>
            <div class="line"></div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="panel-header">
                        <mat-panel-title>Meer over {{docent.naam}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let x of docent.tekst"> {{x.alinea}} </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>