<mat-tab-group #tabs mat-align-tabs="start">
    <mat-tab>
        <div class="inschrijf-tabel">
            <table>
                <tr>
                    <th>Cursus/Opleiding</th>
                    <th>Prijs<span style="color: green;">*</span></th>
                    <th>Doelgroep</th>
                    <th>Docent</th>
                    <th>Dag(en)</th>
                    <th>Tijd</th>
                    <th>Startdatum</th>
                    <th>Plekken vrij</th>
                    <th>Inschrijven</th>
                </tr>
                <tr *ngFor="let o of inschrijfOpleidingen; let i = index" [ngClass]="{'gold': i%2 == 0}">
                    <td> {{o.naam}} </td>
                    <td> {{o?.prijs}}</td>
                    <td> {{o.doelgroep}} </td>
                    <td> {{o.max - o.gereserveerd > 0 ? o.docent : 'n.t.b'}} </td>
                    <td> {{o.max - o.gereserveerd > 0 ? o.dag : 'n.t.b'}} </td>
                    <td> {{o.max - o.gereserveerd > 0 ? o.tijd : 'n.t.b'}} </td>
                    <td class="startdatum-col"> {{o.max - o.gereserveerd > 0 ? o.startdatum : 'n.t.b'}} </td>
                    <td class="indicator-col">
                        <div class="indicator"
                            [ngClass]="{'green': o.max - o.gereserveerd > 0, 'red': o.max - o.gereserveerd < 1}"></div>
                    </td>
                    <td class="button-col"> 
                        <button [ngClass]="{'wachtlijst': o.max - o.gereserveerd < 1}" (click)="addNameToForm(o)" mat-raised-button> 
                            {{o.max - o.gereserveerd < 1 ? 'Wachtlijst' : 'Schrijf in!'}}
                        </button> 
                    </td>
                </tr>
            </table>
        </div>
        <p class="note"><span style="color: green;">*</span>Donateurs ontvangen korting op onze opleidingen! Bekijk de <a href="/#/opleidingen">opleidingen</a> pagina voor meer informatie</p>
    </mat-tab>
    <mat-tab>
        <div class="form-box">
            <div [formGroup]="mainGroup" class="inschrijf-formulier">
                <mat-form-field>
                    <mat-label>Gekozen opleiding</mat-label>
                    <input disabled [value]="gekozenOpleiding" matInput type="text" required/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Voornaam student</mat-label>
                    <input matInput maxlength="50" type="text" formControlName="studentVoorNaam" required>
                    <mat-error>Dit is een verplicht veld</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Achternaam student</mat-label>
                    <input matInput maxlength="50" type="text" formControlName="studentAchterNaam" required>
                    <mat-error>Dit is een verplicht veld</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Geboortedatum</mat-label>
                    <input formControlName="geboorteDatum" matInput type="date" required>
                    <mat-error>Dit is een verplicht veld</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input maxlength="50" placeholder="voorbeeld@hotmail.com" formControlName="email" matInput required type="email">
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error>Dit is een verplicht veld</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Telefoonnummer</mat-label>
                    <input maxlength="20" minlength="8" placeholder="06..." formControlName="telNr" matInput required type="phone">
                    <mat-error>Dit is een verplicht veld</mat-error>
                    <mat-icon matSuffix>stay_primary_portrait</mat-icon>
                </mat-form-field>
                <label>Bent u donateur?</label>
                <mat-radio-group (change)="buttonValidity()" formControlName="donateur" required>
                    <mat-radio-button value="1">Ja</mat-radio-button>
                    <mat-radio-button value="2">Nee</mat-radio-button>
                </mat-radio-group>
                <div [formGroup]="noDonatorGroup" class="second-section animate__animated animate__fadeInDown"  [ngClass]="{'hide': mainGroup.controls.donateur?.value != 2}">
                    <mat-form-field>
                        <mat-label>Straat & huisnummer</mat-label>
                        <input maxlength="50" formControlName="straatEnHuis" matInput type="text" required>
                        <mat-error>Dit is een verplicht veld</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Postcode en woonplaats</mat-label>
                        <input maxlength="50" formControlName="postcodeEnPlaats" matInput type="text" required>
                        <mat-error>Dit is een verplicht veld</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Bankrekeningnummer</mat-label>
                        <input maxlength="18" formControlName="rekNr" matInput type="text">
                        <mat-hint>Uw IBAN zonder spaties.</mat-hint>
                        <mat-error>Ongeldige IBAN</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field class="animate__animated animate__fadeInDown" *ngIf="mainGroup.controls.donateur?.value == 1">
                    <mat-label>Registratienummer</mat-label>
                    <input formControlName="registratieNummer" placeholder="Waarmee u bij ons als donateur bent ingeschreven" matInput type="phone">
                </mat-form-field>
                <button [disabled]="!buttonValidity()" (click)="slaOp()" mat-raised-button>Inschrijving versturen</button>
                <button (click)="setIndexSelectedTab(0)" class="back-button" mat-raised-button>
                    Terug naar alle opleiding inschrijving/start details
                </button>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>