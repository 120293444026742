<p class="title">
    Op dit moment is de cursus '{{data.naam}}' vol en kunt u zich helaas niet meer aanmelden. 
    Vul uw gegevens in. Dan zullen wij contact met u opnemen, zodra wij weer een nieuwe klas gaan starten in shaa' Allah.
</p>
<div class="form" [formGroup]="wachtlijstForm">
    <mat-form-field>
        <mat-label>Voornaam</mat-label>
        <input matInput formControlName="voorNaam" class="naam" type="text" required/>
        <mat-error>Dit is een verplicht veld.</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Achternaam</mat-label>
        <input matInput formControlName="achterNaam" class="naam" type="text" required/>
        <mat-error>Dit is een verplicht veld.</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Geboortedatum</mat-label>
        <input formControlName="geboorteDatum" matInput type="date" required>
        <mat-error>Dit is een verplicht veld</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput formControlName="email" class="naam" type="text" required/>
        <mat-error>U heeft een incorrecte email-adres ingevoerd</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Telefoon</mat-label>
        <input matInput formControlName="telefoon" class="naam" type="text" required/>
        <mat-error>Dit is geen geldig telefoonnummer.</mat-error>
    </mat-form-field>
    <button [disabled]="wachtlijstForm.invalid" color="primary" (click)="sendRequest()" mat-raised-button>Verstuur gegevens voor deze cursus</button>
</div>
