import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-wachtlijstdialog',
  templateUrl: './wachtlijstdialog.component.html',
  styleUrls: ['./wachtlijstdialog.component.scss']
})
export class WachtlijstdialogComponent implements OnInit {

  wachtlijstForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private fs: AngularFirestore,
    private dialogRef: MatDialogRef<WachtlijstdialogComponent>,
    private snackbar: MatSnackBar) { 

    this.wachtlijstForm = new FormGroup({
      voorNaam: new FormControl('',[Validators.required, Validators.minLength(2)]),
      achterNaam: new FormControl('',[Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefoon: new FormControl('', [Validators.required, Validators.minLength(8)]),
      geboorteDatum: new FormControl('', Validators.required)
    });

  }

  ngOnInit(): void {
  }

  sendRequest(){
    if (this.wachtlijstForm.valid){
      this.fs.collection('Wachtlijst').add({
        voorNaam: this.wachtlijstForm.controls.voorNaam.value,
        achterNaam: this.wachtlijstForm.controls.achterNaam.value,
        email: this.wachtlijstForm.controls.email.value,
        telefoon: this.wachtlijstForm.controls.telefoon.value,
        opleiding: this.data,
        aanvraagTijd: new Date(),
        geboorteDatum: this.wachtlijstForm.controls.geboorteDatum.value
      }).then( () => {
        this.snackbar.open('Wachtlijstverzoek verstuurd!','',{
          panelClass: 'success',
          duration: 5000
        }).afterOpened().subscribe( data => {
          this.dialogRef.close();
        });
      }).catch( error => {
        this.snackbar.open('Er ging iets mis! Probeer het later nog een keer', '', {
          panelClass: 'snack',
          duration: 5000
        });
      });
    } else {
      console.log('invalid form');
    }
  }

}
