import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-studenten',
  templateUrl: './studenten.component.html',
  styleUrls: ['./studenten.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentenComponent implements OnInit {

  tekst: string;
  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.fs.collection('studenten').doc('1').valueChanges()
    .subscribe( data => {
      this.tekst = data['tekst'];
    });
  }

}
