import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-bestuur',
  templateUrl: './bestuur.component.html',
  styleUrls: ['./bestuur.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BestuurComponent implements OnInit {

  bestuursleden: any;
  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void {
    this.getBestuurInfo();
  }

  getBestuurInfo(){
    let tempArray = [];
    this.fs.collection("Bestuur").doc('bestuurdata').valueChanges().subscribe( data => {
       tempArray = data['bestuursleden'];
       tempArray.sort((a,b) => a.position - b.position);
       this.bestuursleden = tempArray;
    });
  }
}
