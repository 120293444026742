import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-identiteit',
  templateUrl: './identiteit.component.html',
  styleUrls: ['./identiteit.component.scss']
})
export class IdentiteitComponent implements OnInit {

  pageData: any;
  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void {
    this.getIdentiteitData();
  }

  getIdentiteitData(){
    this.fs.collection('identiteit').doc('1').valueChanges()
    .subscribe( data => {
      this.pageData = data;
    });
  }

}
