import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-organisatie',
  templateUrl: './organisatie.component.html',
  styleUrls: ['./organisatie.component.scss']
})
export class OrganisatieComponent implements OnInit {

  banner: string;
  introTekst: string;
  downloadLink: string;
  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.fs.collection('organisatie').doc('organisatiedata').valueChanges()
    .subscribe(data => {
      this.banner = data['banner'];
      this.introTekst = data['introtekst']
      this.downloadLink = data['downloadlink-beleidsplan'];
    })
  }

  openLink(){
    window.open(`${this.downloadLink}`);
  }

}
