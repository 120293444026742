<div class="banner-box">
    <img src="../../assets/identiteit.jpg" alt="">
    <div class="titel-box">Identiteit</div>
</div>
<div class="identiteit-box">
    <p style="font-weight: bold; font-size: 20px;"> {{pageData?.subtitel}} </p>
    <div class="text-box">
        <p *ngFor="let t of pageData?.introTekst">{{t}}</p>
    </div>
</div>
<div class="missie-en-visie-box">
        <div class="text-box">
                <p>MISSIE & VISIE</p>
                <p [ngClass]="{'cursive': mv.cursief, 'vet': mv.vet}" *ngFor="let mv of pageData?.missieVisie" class="text">{{mv.tekst}}</p>
        </div> 
        <div class="titel-box">
        </div>
</div>