import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-regelsenafspraken',
  templateUrl: './regelsenafspraken.component.html',
  styleUrls: ['./regelsenafspraken.component.scss']
})
export class RegelsenafsprakenComponent implements OnInit {

  regelsdata: any;
  constructor(private fs: AngularFirestore) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.fs.collection('regelsenafspraken').doc('1').valueChanges()
    .subscribe( data => {
      this.regelsdata = data;
    });
  }

  openAlgemeneVoorwaarden(){
    window.open('assets/Algemene voorwaarden Stichting Alhidajah mei 2021.pdf','_blank');
  }

}
