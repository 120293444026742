import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-praktisch',
  templateUrl: './praktisch.component.html',
  styleUrls: ['./praktisch.component.scss']
})
export class PraktischComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
