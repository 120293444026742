<div class="container">
    <div class="contact-opnemen">
        <div class="contact-info">
            <p class="title">CONTACT OPNEMEN</p>
            <div class="contactpersonen-div">
                <div *ngFor="let c of contactPersonen" class="contactpersoon-entry">
                    <div class="naam">
                        {{c.titel}}
                    </div>
                    <div class="email">
                        <a [href]="'mailto:' + c.email">{{c.email}}</a>
                    </div>
                </div>
            </div>
            <p class="address-header">STICHTING ALHIDAJAH</p>
            <p class="less-margin">{{this.straatEnHuisNr}}</p>
            <p class="less-margin">{{this.postcodePlusStad}}</p>
            <p class="less-margin"> {{this.telefoon}} </p>
        </div>
    
        <div class="contact-formulier">
            <div [formGroup]="vragenForm" class="box">
                <mat-form-field>
                    <mat-label>Naam</mat-label>
                    <input matInput formControlName="naam" class="naam" type="text" required/>
                    <mat-error>Dit is geen optioneel veld.</mat-error>
                </mat-form-field>
                <div class="half-box">
                    <mat-form-field class="half added-margin" >
                        <mat-label>E-mail</mat-label>
                        <input matInput formControlName="email" type="text" required/>
                        <mat-error>U heeft een incorrecte email-adres ingevoerd</mat-error>
                    </mat-form-field>
                    <mat-form-field class="half added-margin" >
                        <input matInput formControlName="telefoon" type="text" placeholder="Telefoon"/>
                        <mat-error>Dit is geen geldig telefoonnummer.</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field>
                    <mat-label>Onderwerp</mat-label>
                    <input matInput formControlName="onderwerp" class="naam" type="text" required/>
                    <mat-hint align="start">Minimaal 5 karakters</mat-hint>
                    <mat-error>Dit veld kent minimaal 5 karakters.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Bericht</mat-label>
                    <textarea matInput #input formControlName="bericht" placeholder="Plaats uw bericht hier..." name="" id="" cols="30" rows="10" required></textarea>
                    <mat-hint align="end">{{input.value?.length || 0}}/500</mat-hint>
                    <mat-hint align="start">Minimaal 20 karakters</mat-hint>
                </mat-form-field>
                <button (click)="send()" class="verzend-button" [disabled]="vragenForm.invalid">Verzenden</button>
            </div>
        </div>
    </div>
</div>