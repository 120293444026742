<div class="banner-box">
    <img src="../../assets/home-banner-smaller.png" alt="">
    <div class="titel-box">WELKOM BIJ ALHIDAJAH</div>
</div>
<div class="youtube-div">
    <p class="welkom-titel">WELKOM BIJ ALHIDAJAH</p>
    <p *ngFor="let text of alineas" class="welkom-tekst" [ngClass]="{'bold': text.vet}">{{text.alinea}}</p>
    <iframe class="frame" src="https://www.youtube.com/embed/F40WzCzgDtY?rel=0" frameborder="0" allowfullscreen></iframe>
</div>
<div [ngClass]="{'animate__animated animate__zoomOut': leave}" class="opleidingen">
    <p class="opleiding-titel">ONZE OPLEIDINGEN</p>
    <p class="opleiding-subtitel">{{subtitel}}</p>

    <div class="opleidingen-box">
        <div (click)="goToOpleiding(o)" [ngStyle]="{'background-image' : 'url(' + o.afbeelding + ')'}"  *ngFor="let o of opleidingen" class="opleiding">
            <div class="opleiding-naam-box">
                <span>{{o.naam}}</span>
            </div>
        </div>
    </div>
    <button (click)="goToOpleiding()" class="see-more-button" color="primary" mat-raised-button>Bekijk alle opleidingen</button>
</div>
<app-docenten-widget></app-docenten-widget>
<app-vragenformulier></app-vragenformulier>
