import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { OpleidingenService } from '../opleidingen.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opleidingen',
  templateUrl: './opleidingen.component.html',
  styleUrls: ['./opleidingen.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpleidingenComponent implements OnInit {

  mainGroup: FormGroup;
  noDonatorGroup: FormGroup;
  gekozenOpleiding: string;

  pageData: any;
  opleidingen: any;
  inschrijfOpleidingen: any;
  donateur: any;
  open: boolean;
  firstOpen: boolean;
  selectedOpleiding: any;
  selectedTabIndex: number = 0;
  @ViewChild('panel') panel: MatExpansionPanel;
  @ViewChild('tabs2') tabs: MatTabGroup;

  constructor(private fs: AngularFirestore, private snackbar: MatSnackBar,
    private oplService: OpleidingenService, private router: Router) { }


  ngOnInit(): void {
    this.getOpleidingen();
  }

  ngAfterViewInit(): void {
    this.getRouted();
  }

  getRouted(){
    if (this.oplService.selectedOpleiding != null){
      this.selectedOpleiding = this.oplService.selectedOpleiding;
      this.getDetails(this.oplService.selectedOpleiding);
    }
  }

  getOpleidingen() {
    this.fs.collection("Opleidingen").doc("1").valueChanges().subscribe(
      data => {
        this.pageData = data;
        this.opleidingen = data['opleidingen'];
      });
  }

  getDetails(opleiding: any){
    this.open = true;
    this.setIndexSelectedTab(0);
    this.firstOpen = true;
    this.selectedOpleiding = opleiding;
    this.panel.open();
    window.scrollTo({left: 0, top: 300, behavior: 'smooth'});
  }

  addNameToForm(opleidingName: string){
    this.setIndexSelectedTab(2);
    this.gekozenOpleiding = opleidingName;
  }

  setIndexSelectedTab(index: number){
    window.scrollTo({left: 0, top: 250, behavior: 'smooth'});
    this.tabs.selectedIndex = index;  
  }

  openSnackbar(message: string){
    return this.snackbar.open(message, null , {
      panelClass: 'snack',
      duration: 5000
    });
  }

}
