import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vragenformulier',
  templateUrl: './vragenformulier.component.html',
  styleUrls: ['./vragenformulier.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VragenformulierComponent implements OnInit {

  contactPersonen: any[];
  vragenForm: FormGroup;
  postcodePlusStad: string;
  straatEnHuisNr: string;
  telefoon: string;

  constructor(private fs: AngularFirestore, private snackbar: MatSnackBar) {
    this.vragenForm = new FormGroup({
      naam: new FormControl("",[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      email: new FormControl('',[Validators.required, Validators.minLength(5), Validators.email, Validators.maxLength(100)]),
      telefoon: new FormControl('',[Validators.minLength(7), Validators.required]),
      onderwerp: new FormControl('', [Validators.required, Validators.minLength(5),Validators.maxLength(100)]),
      bericht: new FormControl('',[Validators.required, Validators.minLength(20), Validators.maxLength(500)])
    });
   }

  ngOnInit(): void {
    this.getData();
  }

  
  getData(){
    this.fs.collection('steluwvraag').doc('contactpersonen').valueChanges()
    .subscribe( data => {
      this.contactPersonen = data['contactpersonen'];
    });

    this.fs.collection('adresgegevens').doc('Vs4fPco5P8m2hMQgVAtN').valueChanges()
    .subscribe( data => {
      this.postcodePlusStad = data['postcodeplusstad'];
      this.straatEnHuisNr = data['straatenhuisnr'];
      this.telefoon = data['telefoon'];

      console.log(this.postcodePlusStad + " " + this.straatEnHuisNr + " " + this.telefoon);
    });
  }

  openSnackbar(message: string){
    return this.snackbar.open(message, null , {
      panelClass: 'snack',
      duration: 2000
    });
  }

  send(){
    let objectToAdd = {
      aanvraag: this.vragenForm.value,
      tijdEnDatumAanvraag: new Date()
    }
    if (this.vragenForm.valid){
      this.fs.collection('vragen').add(objectToAdd).then( result => {
        this.openSnackbar("Uw aanvraag is succesvol verstuurd!");
        this.vragenForm.reset();
      }).catch( error => {
        this.openSnackbar("Woops! Er is iets misgegaan! Heeft u wel internet?")
      });
    }
  }

}
